.header__search__icon__btn:focus,
.header__search__icon__btn:hover,
.sidenav__search__icon__btn:focus,
.sidenav__search__icon__btn:hover {
  background: #951c1f;
  outline: none;
}

.side_nav__search {
  padding: 0 50px 0 15px
}

.header__search__icon__btn,
.sidenav__search__icon__btn {
  top: 10px;
  position: absolute;
  color: #fff;
  cursor: pointer;
  background: #121212;
  padding: 4px 6px;
  border: none;
  outline: none;
}

.header__search__input {
  border-radius: 6px;
  border: 1px solid #a3a3a3;
  height: 35px;
  margin-top: 11px;
  font-size: 1rem;
  width: 100%
}

.header__search__icon__btn {
  right: 15px;
  font-size: 16px;
  border: 1px solid #951c1f;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px
}

.sidenav__search__icon__btn {
  right: 51px;
  font-size: 14px;
  border: 1px solid #951c1f;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px
}