.footerDark .thumbContainer {
  background-color: #171821;
}

.footer {
  position: relative;
}

.footer a:not(.social):hover {
  color: #941b20;
}

.footer--white {
  background-color: #fff;
}

.footerDark {
  background-color: #121212;
}

.footerDark a,
.footerDark .widget__title,
.footerDark .social {
  color: #fff;
  border: 0;
}

.footerDark .widget__title {
  color: #fff !important;
  font-size: 1.1rem;
  background: transparent;
  font-weight: 600;
  text-transform: capitalize;
  padding: 0;
  border-bottom: 1px solid #951c1f;
  margin-bottom: 20px;
  width: 100%;
}

.footerDark p {
  color: #dedede !important;
  font-size: 0.8rem;
  padding: 15px;
}

.footerDark .post-list-small__item,
.footerDark .widget_nav_menu li {
  border-color: #121212;
}

.footerDark .entry__meta li {
  color: #5f686b !important;
}

.footerDark .mc4wp-form-fields input[type="email"],
.footerDark .mc4wp-form-fields input[type="text"] {
  border-color: transparent;
}

.footerDark .mc4wp-form-fields input[type="email"]:focus,
.footerDark .mc4wp-form-fields input[type="text"]:focus {
  border-color: #2d95e3;
}

.footerDark .newsletter__icon {
  color: #575961;
}

.footerDark .tagcloud a {
  background-color: #2d2e36;
  color: #fff;
}

.footer__widgets {
  padding: 40px 0;
}

.footer__widgets p {
  font-size: 14px;
  line-height: 26px;
}

@media only screen and (max-width: 991px) {
  .footer__widgets>.row>div:not(:last-child) {
    margin-bottom: 50px;
  }
}

.footer__widgets--short {
  padding: 60px 0;
}

.footer .logo__img {
  display: block;
  padding-bottom: 20px;
}

.footer--bg-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer--bg-img:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #121212;
}

.footer__widgets__top {
  position: relative;
}

.footer__nav__menu li {
  display: inline-block;
  margin: 10px 0px;
  padding: 0px 10px;
  border-right: 1px solid #f7f7f7;
}

.footer__nav__menu li:last-child {
  border-right: none;
}

.footer__nav__menu a {
  color: #fff;
}

.footer__nav__menu a:hover {
  color: #941b20;
}

.footer__nav__menu__1 {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 400;
}

.copyright {
  color: #999;
  display: inline-block;
  font-size: 1rem !important;
  margin: 0px;
}

.copyright a {
  color: inherit;
}

.copyright a:hover {
  color: #941b20;
}

.footer__bottom {
  padding: 20px 0;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .footer__bottom .copyright {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 776px) {
  .copyright {
    display: grid;
  }
}

.footer__bottom .widget_nav_menu li {
  display: inline-block;
  margin-right: 15px;
  border-bottom: 0;
  padding: 0;
}

.footer__bottom .widget_nav_menu li:last-child {
  margin-right: 0;
}

.footer__bottom__dark {
  background-color: #0e0f14;
}

.footer__bottom__dark .footer__nav__menu a {
  color: #fff;
}

.footer__bottom__dark .footer__nav__menu a:hover {
  color: #941b20;
}

.footer__bottom--white {
  background-color: #fff;
}

.style-games .footer__widgets {
  padding: 56px 0;
}

.style-games .footer .widget__title {
  color: #2d95e3;
  font-size: 14px;
}

.style-games .footer__nav__menu+.copyright {
  margin-top: 24px;
}

.widget ul li {
  padding: 5px 0;
}

.widget_tag_cloud a,
.entry__tags a {
  padding: 6px 10px;
  line-height: 1;
  margin: 0 8px 8px 0;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #f7f7f7;
  border-radius: 6px;
  color: #121212;
  display: inline-block;
  float: left;
}

.widget_tag_cloud a:hover,
.entry__tags a:hover {
  background-color: #2d95e3 !important;
  border-color: transparent;
  color: #fff !important;
}

.tagcloud {
  overflow: hidden;
}

.nowrap__text {
  text-wrap: nowrap;
}

.btnstyle {
  position: absolute;
  color: #fff;
  cursor: pointer;
  background: #121212;
  padding: 7px 7px 6px;
  border: none;
  outline: none;
  right: 29px;
  font-size: 16px;
  border: 1px solid #951c1f;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.btnstyle:focus,
.btnstyle:hover {
  background: #951c1f;
  outline: none;
}

.newsletter {
  width: 100%;
}

.newsletter_input {
  border-radius: 6px;
  border: 1px solid #951c1f;
  height: 37px;
  font-size: 1rem;
  width: 100%;
}