.nav,
.nav__menu,
.nav__menu>li>a {
  position: relative;
}

.nav {
  min-height: 48px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 120;
  -webkit-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
}

.nav__wrap {
  padding-left: 40px;
}

.nav__dropdown-menu,
.nav__menu {
  list-style: none;
}

.nav__menu {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav__menu>li.active a:before,
.nav__menu>li:hover a:before {
  width: 100%;
}

.nav__menu>li>a {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: none;
  padding: 0 16px;
  display: block;
  line-height: 20px;
}

.nav__menu>li>a:hover {
  color: #121212;
}

.nav__menu>li>a:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.nav__dropdown>a:after {
  content: "\f123";
  font-family: ui-icons;
  margin-left: 7px;
  font-size: 10px;
  line-height: 1;
}

.nav__dropdown .nav__dropdown>a:after {
  content: "\e804";
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mobile body {
  cursor: pointer;
}

.header {
  padding: 0;
}

.header__menu-list li {
  display: inline-block;
  font-size: 14px;
  margin-right: 19px;
}

.header__menu-list a {
  color: #fff;
}

@media only screen and (min-width: 992px) {
  .nav__menu>li {
    display: inline-block;
    text-align: center;
  }

  .nav__menu>li a {
    font-size: 1.2rem;
    padding: 12px 8px;
  }

  .nav__dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 10px 0;
    z-index: 1000;
    min-width: 200px;
    width: 100%;
    text-align: left;
    list-style: none;
    white-space: nowrap;
    background-color: #fff;
    -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    display: block;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
  }

  .nav__dropdown-menu>li>a {
    color: #54555e;
    padding: 8px 22px;
    font-size: 14px;
    display: block;
  }

  .nav__dropdown-menu>li>a:hover {
    color: #941b20;
  }

  .nav__dropdown-menu.hide-dropdown {
    visibility: hidden !important;
    opacity: 0 !important;
  }

  .nav__dropdown-menu--right {
    right: 0;
  }

  .nav__dropdown:hover>.nav__dropdown-menu {
    opacity: 1;
    visibility: visible;
  }

  .nav__dropdown-menu .nav__dropdown-menu {
    left: 100%;
    top: -10px;
  }

  .nav__dropdown .nav__dropdown {
    position: relative;
  }

  .nav__megamenu {
    padding: 32px 30px;
  }

  .nav__megamenu .row {
    white-space: normal;
  }

  .nav__megamenu-item .entry {
    margin-bottom: 0;
  }

  .nav__megamenu-item .entry__title {
    font-size: 16px;
  }

  .nav__megamenu-list a {
    display: block;
    clear: both;
    padding: 4px 0;
    white-space: nowrap;
    color: #b2b2b2;
    font-size: 15px;
  }

  .nav__1 .container:after,
  .nav__1 .container:before {
    content: "";
    display: block;
    position: absolute;
    left: 15px;
    right: 15px;
    height: 2px;
    border-top: 1px solid #e3e4e8;
  }

  .nav__1 .container:after {
    bottom: 0;
  }
}

.nav__1 .nav__holder {
  background-color: #b71d20;
  background-image: linear-gradient(45deg,
      #a21219fc 25%,
      transparent 25%,
      transparent 75%,
      #a21219fc 75%),
    linear-gradient(45deg,
      #a21219fc 25%,
      transparent 25%,
      transparent 75%,
      #a21219fc 75%);
  background-size: 3px 3px;
  background-position: 0 0, 2px 2px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 2px 2px 2px rgb(0 0 0 / 10%),
    0 1px 2px 2px rgb(0 0 0 / 10%), 0 2px 2px 2px rgb(0 0 0 / 10%);
  box-shadow: 0 2px 2px 2px rgb(0 0 0 / 10%), 0 1px 2px 2px rgb(0 0 0 / 10%),
    0 2px 2px 2px rgb(0 0 0 / 10%);
}

.nav__1 .nav__wrap {
  padding-left: 0;
  -webkit-box-flex: 4;
  -ms-flex: 4 0 0px;
  flex: 4 0 0;
  min-height: 44px;
}

.header__menu ul li a,
.nav__wrap ul li a {
  text-decoration: none;
}

.nav__wrap ul li a:hover {
  color: #121212;
  font-size: 1.2rem;
}

.nav__1 .nav__menu>li>a:before {
  display: none;
}

.home .nav__1 .container:after,
.nav__1 .nav__holder.scrolling .container:after,
.nav__1 .nav__holder.scrolling .container:before {
  display: none;
  text-decoration: none;
}

@media only screen and (max-width: 991px) {
  .nav__header {
    height: 48px;
  }

  .nav__wrap {
    text-align: left;
  }

  .logo {
    position: absolute;
    left: 75px;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 40px;
    line-height: 48px;
    text-align: center;
  }

  .logo img {
    height: 55px !important;
    width: 125px !important;
    padding-left: 30px !important;
  }

  .onlyDesk {
    display: none;
  }

  .nav--sticky {
    height: 65px;
  }

  .nav__right-item {
    margin-right: 0;
  }
}

@media only screen and (max-width: 775px) {
  .logo {
    position: absolute;
    left: 100px;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 25px;
    line-height: 48px;
    text-align: center;
  }
}

@media only screen and (min-width: 1024px) {
  .logo {
    position: absolute;
    left: 100px;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 40px;
    line-height: 45px;
    text-align: center;
  }

  .nav__menu>li a {
    font-size: 1.2rem;
    padding: 12px 10px;
  }

  .logo img {
    padding-left: 5px !important;
    height: 55px !important;
    width: 125px !important;
    margin-top: 0;
  }
}

@media only screen and (max-width: 776px) {
  .logo {
    position: absolute;
    left: 100px;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 40px;
    line-height: 45px;
    text-align: center;
  }

  .logo img {
    padding-left: 5px !important;
    height: 55px !important;
    width: 125px !important;
    margin-top: 8px;
  }
}

.nav__icon__toggle {
  padding: 0;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: 0.15s linear;
  transition: 0.15s linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin-top: 32px;
  margin-right: 15px;
  overflow: visible;
  float: left;
}

.nav__icon__toggle:focus {
  outline: 0;
}

.nav__icon__toggle__box {
  width: 18px;
  height: 20px;
  position: relative;
  display: block;
}

.nav__icon__toggle__inner {
  display: block;
  top: 50%;
  margin-top: -1px;
  margin-left: 3px;
  width: 15px;
}

.nav--align-right,
.nav__right,
.nav__search {
  margin-left: auto;
}

.nav__icon__toggle__inner,
.nav__icon__toggle__inner:after,
.nav__icon__toggle__inner:before {
  height: 2px;
  background-color: #fff;
  position: absolute;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.nav__icon__toggle:hover .nav__icon__toggle__inner,
.nav__icon__toggle:hover .nav__icon__toggle__inner:after,
.nav__icon__toggle:hover .nav__icon__toggle__inner:before {
  background-color: #bcbcbc;
}

.nav__icon__toggle__inner:after,
.nav__icon__toggle__inner:before {
  content: "";
  display: block;
  margin-left: -3px;
}

.nav__icon__toggle__inner:before {
  top: -6px;
  width: 18px;
}

.nav__icon__toggle__inner:after {
  bottom: -6px;
  width: 18px;
}

header .flex-parent {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}

header .flex__child {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  line-height: 1;
}

.nav--align-left {
  margin-right: auto;
}

.nav__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.nav__right a:focus,
.nav__right a:hover {
  color: #941b20;
}

.nav__search-box {
  width: 300px;
  position: absolute;
  right: 0;
  top: 100%;
  padding: 15px 20px;
  background-color: #f7f7f7;
  display: none;
  -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.nav__search-form,
.nav__search-mobile-form {
  position: relative;
}

.nav__search-input {
  margin-bottom: 0;
  display: block;
  line-height: 40px;
}

.nav__search-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 !important;
  width: 42px;
}

.nav__search-trigger {
  color: #171821;
  font-size: 23px;
  display: inline-block;
  width: 24px;
  height: 48px;
  line-height: 48px;
  text-align: center;
}

.nav__search .ui-close {
  font-size: 20px;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .nav__search-box {
    width: 100%;
  }
}

.nav__search-mobile-input {
  height: 46px;
  border: 0;
  background-color: transparent;
  margin-bottom: 0;
  font-size: 13px;
  color: #171821;
  border-radius: 0;
  border-bottom: 1px solid #182835;
}

.nav__search-mobile-input:focus {
  background-color: transparent;
  border-color: #fff;
}

.nav__search-mobile-submit {
  position: absolute;
  right: 0;
  top: 0;
  width: 46px;
  height: 46px;
  border: 0;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  color: #9aa3ab;
  background-color: transparent;
}

input.nav__search-mobile-input::-webkit-input-placeholder {
  color: #9aa3ab;
}

input.nav__search-mobile-input:-moz-placeholder {
  color: #9aa3ab;
  opacity: 1;
}

input.nav__search-mobile-input::-moz-placeholder {
  color: #9aa3ab;
  opacity: 1;
}

input.nav__search-mobile-input:-ms-input-placeholder {
  color: #9aa3ab;
}

@media (min-width: 374px) {
  .nav__icon__toggle {
    margin-top: 24px !important;
  }
}

@media (min-width: 1024px) {
  .nav__icon__toggle {
    margin-top: 50px !important;
  }
}

@media (min-width: 1275px) {
  .nav__icon__toggle {
    margin-top: 34px !important;
  }
}

@media (min-width: 1400px) {
  .nav__icon__toggle {
    margin-top: 34px !important;
  }
}


.loginLink {
  margin-top: 2px;
  display: inline-flex;
}

.loginLink li {
  float: left;
  margin-top: 5px;
}

.loginLink li:last-child::before {
  content: "|";
  color: #b6b7bb;
  float: left;
  padding-right: 10px;
}


.loginLink li a {
  padding: 3px 15px 0px 0px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: none;
  display: block;
  line-height: 20px;
  white-space: nowrap;
}

.loginLink li a:hover {
  color: #000 !important;
}